import axios from 'axios'
import { getAuthToken } from '@/utils/auth'

class UploadProvider {
  // eslint-disable-next-line class-methods-use-this
  async upload(file) {
    const formData = new FormData()
    formData.append('file', file)
    const authData = getAuthToken()
    const { data } = await axios.post(`${process.env.VUE_APP_API}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        [authData.key]: authData.value
      }
    })

    return { data }
  }
}

export default UploadProvider
