<template>
  <v-row class="match-height">
    <v-col cols="12">
      <v-card>
        <v-card-title>
          Upload MP3
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="validForm"
            :lazy-validation="false"
            class="multi-col-validation"
          >
            <v-row>
              <v-col
                cols="12"
                md="12 "
              >
                <v-file-input
                  v-model="file"
                  :rules="rules.file"
                  accept="audio/mpeg3"
                  label="Upload MP3"
                  outlined
                  dense
                  @change="upload"
                >
                </v-file-input>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="form.title"
                  label="Title"
                  :rules="rules.title"
                  outlined
                  dense
                  placeholder="title"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  label="Type"
                  value="upload"
                  readonly
                  outlined
                  dense
                  placeholder="Type"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-select
                  v-model="form.lang"
                  label="Language"
                  :items="langs"
                  :rules="rules.lang"
                  item-value="code"
                  item-text="name"
                  outlined
                  dense
                  placeholder="Language"
                />
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-select
                  v-model="form.alternativeLangs"
                  label="Alternative Language"
                  :items="langs"
                  item-value="code"
                  item-text="name"
                  outlined
                  multiple
                  dense
                  placeholder="alternative Language"
                  hide-details
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-text-field
                  v-model="form.note"
                  label="Note"
                  outlined
                  dense
                  placeholder="Note"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-combobox
                  v-model="form.tags"
                  outlined
                  dense
                  :append-icon="'mdi-pencil-outline'"
                  hide-selected
                  hint="Press Enter for add some tags."
                  label="Tags"
                  multiple
                  small-chips
                >
                </v-combobox>
              </v-col>

              <v-col cols="12">
                <v-btn
                  color="primary"
                  :loading="loading"
                  @click="loading ? () => {} : createTrack()"
                >
                  Submit
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import humps from 'humps'
import MasterDataProvider from '@/resources/master-data'
import TrackProvider from '@/resources/track'
import UploadProvider from '@/resources/upload'

const MasterDataService = new MasterDataProvider()
const TrackService = new TrackProvider()
const UploadService = new UploadProvider()
export default {
  data() {
    return {
      loading: false,
      file: null,
      form: {
        url: '',
        videoId: '',
        mp3Url: '',
        title: '',
        type: 'upload',
        lang: '',
        alternativeLangs: [],
        tags: [],
        note: ''
      },
      validForm: false,
      rules: {
        file: [
          v => !!v || 'Please upload mp3 file.',
          v => v != null || 'Please upload mp3 file.'
        ],
        title: [
          v => !!v || 'Please fill the Title.'
        ],
        lang: [
          v => !!v || 'Please fill the Language.'
        ],
        url: [
          v => !!v || 'Please fill the Url.',
          v => /^(https?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/.test(v) || 'Invalid youtube url'
        ]
      },
      langs: []
    }
  },
  async mounted() {
    await this.getlang()
  },
  methods: {
    async upload(file) {
      try {
        this.loading = true
        const { data } = await UploadService.upload(file)
        if (data) {
          const camelData = humps.camelizeKeys(data)
          this.form.url = camelData.publicUrl
          this.form.mp3Url = camelData.publicUrl
          this.form.videoId = camelData.randomName
          this.$store.dispatch('Style/setSnackbar', {
            message: 'Upload success',
            type: 'success',
            active: true
          })
        }
      } catch (err) {
        this.$store.dispatch('Style/setSnackbar', {
          message: err.message,
          type: 'red',
          active: true
        })
      } finally {
        this.loading = false
      }
    },
    async getlang() {
      const { data } = await MasterDataService.getLangs()
      Object.keys(data).forEach(k => {
        this.langs.push({
          code: k,
          name: data[k]
        })
      })
    },
    async createTrack() {
      this.$refs.form.validate()
      if (!this.validForm) return
      try {
        this.loading = true
        const result = await TrackService.createTrackUpload(this.form)
        if (result) {
          this.$store.dispatch('Style/setSnackbar', {
            message: 'Success',
            type: 'success',
            active: true
          })
          this.$router.push({ name: 'trackList' })
        }
      } catch (err) {
        this.$store.dispatch('Style/setSnackbar', {
          message: `Error ${err.message}`,
          type: 'pink',
          active: true
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
